import { Action, createReducer, on } from '@ngrx/store';

import { BasicState, buildInitialBasicState } from '@mp/shared/data-access';

import { Benutzer } from '../benutzer';

import { BenutzerActions } from './benutzer.actions';

export const BENUTZER_FEATURE_KEY = 'benutzer';

export interface BenutzerPartialState {
  readonly [BENUTZER_FEATURE_KEY]: BenutzerState;
}

export interface BenutzerState extends BasicState<Benutzer> {
  dataStreams: string[];
}
export const initialState: BenutzerState = {
  ...buildInitialBasicState(),
  dataStreams: [],
};

const reducer = createReducer(
  initialState,

  on(
    BenutzerActions.COMPONENT.loadSingle,
    BenutzerActions.COMPONENT.loadAll,
    BenutzerActions.COMPONENT.create,
    BenutzerActions.COMPONENT.update,

    (state): BenutzerState => ({
      ...state,
      isLoading: true,
    }),
  ),

  on(
    BenutzerActions.API.loadedSingleSuccessfully,

    (state, action): BenutzerState => ({
      ...state,
      isLoading: false,
      selected: action.loadedBenutzer,
    }),
  ),

  on(
    BenutzerActions.API.loadedAllSuccessfully,

    (state, action): BenutzerState => ({
      ...state,
      isLoading: false,
      entities: action.loadedBenutzerPage.data,
      pagination: action.loadedBenutzerPage.pagination,
    }),
  ),

  on(
    BenutzerActions.API.createdSuccessfully,

    (state, action): BenutzerState => ({
      ...state,
      isLoading: false,
      entities: [action.createdBenutzer, ...(state.entities ?? [])],
      selected: undefined,
    }),
  ),

  on(
    BenutzerActions.API.updatedSuccessfully,

    (state, { updatedBenutzer }): BenutzerState => {
      return {
        ...state,
        isLoading: false,
        entities: getUpdatesUserEntities(updatedBenutzer, state.entities),
        selected: undefined,
      };
    },
  ),

  on(
    BenutzerActions.API.canceledUpdate,
    BenutzerActions.API.canceledCreation,

    (state): BenutzerState => ({
      ...state,
      isLoading: false,
      selected: undefined,
    }),
  ),

  on(
    BenutzerActions.API.updateUserBlockSuccessfully,
    BenutzerActions.API.updateUserBussinessIntelligenceSuccessfully,

    (state, { updatedUser }): BenutzerState => {
      return {
        ...state,
        isLoading: false,
        entities: getUpdatesUserEntities(updatedUser, state.entities),
        selected: updatedUser,
      };
    },
  ),

  on(
    BenutzerActions.API.fetchAvailableDataStreamsSuccessfully,

    (state, { dataStreams }): BenutzerState => {
      return {
        ...state,
        dataStreams,
      };
    },
  ),
);

function getUpdatesUserEntities(updatedUser: Benutzer, allUsers: Benutzer[] = []): Benutzer[] {
  return [...allUsers.map((entity) => (entity.id === updatedUser.id ? updatedUser : entity))];
}

export function benutzerReducer(state: BenutzerState | undefined, action: Action): BenutzerState {
  return reducer(state, action);
}
