import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiBaseService } from '@mp/shared/data-access';
import { UserAdditionalInfoResponse, UserAdditionalInfoService } from '@mp/shared/user/additional-info/data-access';

import { Benutzer, CreateBenutzer, UpdateBenutzer, UserBlockRequest } from '..';

import { UserActionRequest } from './user-action-request';
import { UserActionType } from './user-action-type';
import { UserActionUrlResponse } from './user-action-url-response';
import { UserBusinessIntelligence } from './user-business-intelligence';

// Temporärer Workaround!
type DataWrapper = {
  data: CreateBenutzerNew;
};

type CreateBenutzerNew = {
  entity: Benutzer;
  statusCode: number;
  statusMessage: string;
};

@Injectable({ providedIn: 'root' })
export class BenutzerService
  extends ApiBaseService<Benutzer, CreateBenutzer, UpdateBenutzer>
  implements UserAdditionalInfoService
{
  constructor(http: HttpClient) {
    super(http, '/api/core/authorization/benutzerverwaltung');
  }

  createBenutzer(benutzerToCreate: CreateBenutzer): Observable<DataWrapper> {
    return this.http.post<DataWrapper>(this.baseUrl, benutzerToCreate);
  }

  changePassword(id: number, passwort: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/${id}/reset`, { passwort });
  }

  updateUserBlock(id: number, userBlockRequest: UserBlockRequest): Observable<Benutzer> {
    return this.http.post<Benutzer>(`${this.baseUrl}/${id}/block`, userBlockRequest);
  }

  getUserAdditionalInfo(id: number): Observable<UserAdditionalInfoResponse> {
    return this.http.get<UserAdditionalInfoResponse>(`${this.baseUrl}/${id}/info`);
  }

  sendUserActivationEmail(id: number): Observable<void> {
    return this.sendUserActionEmail(id, { type: UserActionType.ActivateUser });
  }

  sendResetPasswordEmail(id: number): Observable<void> {
    return this.sendUserActionEmail(id, { type: UserActionType.ResetPassword });
  }

  private sendUserActionEmail(id: number, userActionRequest: UserActionRequest): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${id}/send-email`, userActionRequest);
  }

  generateUserActivationUrl(id: number): Observable<UserActionUrlResponse> {
    return this.generateUserActionUrl(id, { type: UserActionType.ActivateUser });
  }

  generateResetPasswordUrl(id: number): Observable<UserActionUrlResponse> {
    return this.generateUserActionUrl(id, { type: UserActionType.ResetPassword });
  }

  private generateUserActionUrl(id: number, userActionRequest: UserActionRequest): Observable<UserActionUrlResponse> {
    return this.http.post<UserActionUrlResponse>(`${this.baseUrl}/${id}/action-url`, userActionRequest);
  }

  exportAll(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/export`, {});
  }

  fetchAvailableDataStreams(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/bi-data-streams`);
  }

  updateUserBusinessIntelligence(id: number, userBusinessIntelligence: UserBusinessIntelligence): Observable<Benutzer> {
    return this.http.put<Benutzer>(`${this.baseUrl}/${id}/bi`, { ...userBusinessIntelligence });
  }
}
