import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BENUTZER_FEATURE_KEY, BenutzerState } from './benutzer.reducer';

const getBenutzerState = createFeatureSelector<BenutzerState>(BENUTZER_FEATURE_KEY);

const IS_LOADING = createSelector(getBenutzerState, (state) => state.isLoading);

const LIST = createSelector(getBenutzerState, (state) => state.entities);

const SELECTED = createSelector(getBenutzerState, (state) => state.selected);

const DATA_STREAMS = createSelector(getBenutzerState, (state) => state.dataStreams);

export const benutzerSelectors = {
  IS_LOADING,
  LIST,
  SELECTED,
  DATA_STREAMS,
};
